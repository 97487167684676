import styled from 'styled-components'
import { removeSelect } from '../../../styled/extends'
import { mapPage } from '../../../consts/mapPageConsts'
import { siteBreakPoints, colors } from '../../../consts/styleConsts'

const { pageWidth, pageHeight } = mapPage
const backgroundColor = colors.background.dark
const { small } = siteBreakPoints
const width1500 = 1500

export const PageWrapper = styled.div`
  width: 100vw;
  min-height: -webkit-fill-available;
  height: 100%;
  overflow: scroll;
  position: fixed;
  -webkit-overflow-scrolling: touch;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`
export const CanvasWrapper = styled.div`
  width: ${pageWidth + 100}px;
  height: ${pageHeight + 100}px;
  background: ${backgroundColor};
  position: relative;
  left: 0;
  top: 0;
  cursor: grab;
  ${removeSelect};
  pointer-events: auto;
  &:active {
    cursor: grabbing;
  }
  @media only screen and (max-width: ${width1500}px) {
    width: ${100 + pageWidth * 0.5}px;
    height: ${100 + pageHeight * 0.5}px;
  }
  @media only screen and (max-width: ${small}px) {
    width: ${100 + pageWidth * 0.33}px;
    height: ${100 + pageHeight * 0.35}px;
  }
`
