import React, { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import useLinesCanvas from '../../../hooks/useLinesCanvas'
import useResize from '../../../hooks/useResize'

import { mapPage } from '../../../consts/mapPageConsts'

import { Canvas } from './styled'

const MapCanvasLayer = () => {
  const { pageWidth, pageHeight } = mapPage
  const { scale, addResizeListener, removeResizeListener } = useResize()
  const { eyesState } = useSelector(state => state)
  const { drawLines } = useLinesCanvas(eyesState)
  const canvasRef = useRef(null)

  const canvasWidth = Math.ceil(pageWidth * scale)
  const canvasHeight =
    scale === 0.33
      ? Math.ceil(pageHeight * 0.35)
      : Math.ceil(pageHeight * scale)

  useEffect(() => {
    addResizeListener()
    drawLines(canvasRef.current, scale)

    return () => removeResizeListener()
  }, [drawLines, scale, addResizeListener, removeResizeListener])

  return <Canvas ref={canvasRef} width={canvasWidth} height={canvasHeight} />
}

export default React.memo(MapCanvasLayer)
