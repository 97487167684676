import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import LoadingScreen from '../LoadingScreen/LoadingScreen'
import { navigate } from 'gatsby'
import { checkIfFirstTimeUser } from '../../../redux/isFirstUserState/actions'

export default Component => props => {
  const [isLoading, setIsloading] = useState(true)
  const dispatch = useDispatch()
  const { checked, isFirstTime } = useSelector(state => state.isFirstTimeUser)

  useEffect(() => {
    if (!checked) {
      dispatch(checkIfFirstTimeUser)
    }
    if (checked) {
      if (isFirstTime) {
        navigate('/intro')
      } else {
        setIsloading(false)
      }
    }
  }, [dispatch, checked, isFirstTime])

  return (
    <>
      {isLoading ? (
        <LoadingScreen isLoading={isLoading} />
      ) : (
        <Component {...props} />
      )}
    </>
  )
}
