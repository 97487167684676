import styled from 'styled-components'
import { zIndex, colors, siteBreakPoints } from '../../../consts/styleConsts'
import {
  removeBackgroundOnClick,
  removeImageDrag
} from '../../../styled/extends'
import { Link } from 'gatsby'
import { mapPage } from '../../../consts/mapPageConsts'

const { pageWidth, pageHeight } = mapPage
const backgroundColor = colors.background.dark
const { eyesIndex } = zIndex
const { small } = siteBreakPoints
const width1500 = 1500

export const Wrapper = styled.div`
  width: ${pageWidth}px;
  height: ${pageHeight}px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: ${eyesIndex};
  @media only screen and (max-width: ${width1500}px) {
    width: ${pageWidth * 0.5}px;
    height: ${pageHeight * 0.5}px;
  }
  @media only screen and (max-width: ${small}px) {
    width: ${pageWidth * 0.33}px;
    height: ${pageHeight * 0.35}px;
  }
`
export const EyeWrapper = styled(Link)`
  display: block;
  left: ${({ x }) => x}%;
  top: ${({ y }) => y}%;
  width: 150px;
  height: 85px;
  position: absolute;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: ${eyesIndex};
  ${removeBackgroundOnClick};
  ${removeImageDrag};
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 65%;
    height: 65%;
    background: ${backgroundColor};
  }
  & img:-moz-loading {
    visibility: hidden;
  }
  & img {
    ${removeImageDrag};
    position: absolute;
    width: 100%;
    height: 100%;
    transition: 0.4s ease;
    display: block;
  }
  & img:nth-child(2) {
    left: 6px;
    top: 11px;
  }
  & img:nth-child(1) {
    opacity: ${({ viewed }) => (viewed ? 1 : 0)};
    left: 6px;
    top: -12px;
  }
  &:hover {
    & img:nth-child(2) {
      opacity: 0;
    }
    & img:nth-child(1) {
      opacity: 1;
    }
  }
  @media only screen and (max-width: ${width1500}px) {
    width: 75px;
    height: 42px;
    & img:nth-child(2) {
      left: 2px;
      top: 6px;
    }
    & img:nth-child(1) {
      left: 3px;
      top: -5px;
    }
  }
  @media only screen and (max-width: ${small}px) {
    width: 65px;
    height: 37px;
    & img:nth-child(2) {
      left: 2px;
      top: 4px;
    }
    & img:nth-child(1) {
      left: 2px;
      top: -4px;
    }
  }
`
export const MainEyeWrapper = styled(Link)`
  display: block;
  left: ${({ x }) => x}%;
  top: ${({ y }) => y}%;
  width: 472px;
  height: 266px;
  position: absolute;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: ${eyesIndex};
  ${removeBackgroundOnClick};
  ${removeImageDrag};
  & img:-moz-loading {
    visibility: hidden;
  }
  & img {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    left: 18px;
    top: -37px;
    ${removeImageDrag};
  }
  @media only screen and (max-width: ${width1500}px) {
    width: 236px;
    height: 133px;
    & img {
      left: 10px;
      top: -19px;
    }
  }
  @media only screen and (max-width: ${small}px) {
    width: 164px;
    height: 93px;
  }
`
