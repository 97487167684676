import React, { useRef, useEffect } from 'react'
import useParticlesCanvas from '../../../hooks/useParticlesCanvas'
import useResize from '../../../hooks/useResize'

import { mapPage } from '../../../consts/mapPageConsts'

import { Canvas } from './styled'

const ParticlesLayer = () => {
  const { pageWidth, pageHeight } = mapPage

  const { scale, addResizeListener, removeResizeListener } = useResize()
  const { drawParticles } = useParticlesCanvas(188, 42)
  const canvasRef = useRef(null)

  const calcWidthHeightCanvas = () => {
    let canvasWidth = 0
    let canvasHeight = 0
    if (scale === 1) {
      canvasWidth = pageWidth + 100
      canvasHeight = pageHeight + 100
    }
    if (scale === 0.5) {
      canvasWidth = Math.ceil(100 + pageWidth * scale)
      canvasHeight = Math.ceil(100 + pageHeight * scale)
    }
    if (scale === 0.33) {
      canvasWidth = Math.ceil(100 + pageWidth * scale)
      canvasHeight = Math.ceil(100 + pageHeight * 0.35)
    }
    return { canvasWidth, canvasHeight }
  }
  const { canvasWidth, canvasHeight } = calcWidthHeightCanvas()

  useEffect(() => {
    addResizeListener()
    drawParticles(canvasRef.current, scale)

    return () => removeResizeListener()
  }, [drawParticles, addResizeListener, removeResizeListener, scale])

  return <Canvas ref={canvasRef} width={canvasWidth} height={canvasHeight} />
}

export default React.memo(ParticlesLayer)
