import React, { useEffect, useRef, useState } from 'react'
import useScroll from '../../../hooks/useScroll'
import useMapNavigation from '../../../hooks/useMapPageNavigation'

import MapEyesLayer from '../MapEyesLayer/MapEyesLayer'
import MapLinesLayer from '../MapLinesLayer/MapLinesLayer'
import MapTextLayer from '../MapTextLayer/MapTextLayer'
import MapParticlesLayer from '../MapParticlesLayer/MapParticlesLayer'
import { CanvasWrapper, PageWrapper } from './styled'
import LoadingScreen from '../../Shared/LoadingScreen/LoadingScreen'

const MapPageWrapper = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { scrollToCenter } = useScroll()
  const pageWrapperRef = useRef(null)
  const {
    setScrollElement,
    removeWheelScroll,
    onMouseDown,
    onMouseUp,
    onMouseMove,
    addScrollListener
  } = useMapNavigation()

  useEffect(() => {
    scrollToCenter(pageWrapperRef.current)
    setScrollElement(pageWrapperRef.current)
    removeWheelScroll()
    addScrollListener()
    setIsLoading(false)
  }, [scrollToCenter, setScrollElement, removeWheelScroll, addScrollListener])

  return (
    <>
      {isLoading ? <LoadingScreen isLoading={isLoading} /> : null}
      <PageWrapper ref={pageWrapperRef}>
        <CanvasWrapper
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
          onTouchMove={onMouseMove}
        >
          <MapParticlesLayer />
          <MapLinesLayer />
          <MapTextLayer />
          <MapEyesLayer scrollElement={pageWrapperRef.current} />
        </CanvasWrapper>
      </PageWrapper>
    </>
  )
}

export default MapPageWrapper
