import styled from 'styled-components'
import { zIndex, siteBreakPoints } from '../../../consts/styleConsts'

import { mapPage } from '../../../consts/mapPageConsts'
const { pageWidth, pageHeight } = mapPage
const { small } = siteBreakPoints
const width1500 = 1500

const { particlesIndex } = zIndex

export const Canvas = styled.canvas`
  width: ${pageWidth + 100}px;
  height: ${pageHeight + 100}px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${particlesIndex};
  @media only screen and (max-width: ${width1500}px) {
    width: ${100 + pageWidth * 0.5}px;
    height: ${100 + pageHeight * 0.5}px;
  }
  @media only screen and (max-width: ${small}px) {
    width: ${100 + pageWidth * 0.33}px;
    height: ${100 + pageHeight * 0.35}px;
  }
`
