import React from 'react'

import { Title, InstructionText, LogoContainer, LogoText, Logo } from './styled'

const MapTextLayer = React.memo(() => {
  return (
    <>
      <Title>SEE THE CHANGE</Title>
      <InstructionText>
        Open all eyes to check what are the consequences for the earth
      </InstructionText>
      <LogoContainer href='https://conceptik.com/' target='_blank'>
        <LogoText>Web design and development</LogoText>
        <Logo />
      </LogoContainer>
    </>
  )
})

export default MapTextLayer
