import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useStaticQuery, graphql } from 'gatsby'

import { saveCoordinates } from '../../../redux/mapRecenterState/actions'
import { checkLocalStorageEyesState } from '../../../redux/eyesState/actions'

import eyeMainSvg from '../../../images/eye-main.svg'
import eyeClosedSvg from '../../../images/eye-closed.svg'
import eyeHoveredSvg from '../../../images/eye-hovered.svg'

import { MainEyeWrapper, EyeWrapper, Wrapper } from './styled'

const MapEyesLayer = ({ scrollElement }) => {
  const eyesState = useSelector(({ eyesState }) => eyesState)
  const dispatch = useDispatch()
  const postsQuery = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        nodes {
          frontmatter {
            slug
            canvasPoint
          }
        }
      }
    }
  `)
  const posts = postsQuery.allMarkdownRemark.nodes
  const getURL = name => {
    const post = posts.find(v => v.frontmatter.canvasPoint === name)
    return post.frontmatter.slug
  }
  const onEyeClick = () => {
    const { scrollLeft, scrollTop } = scrollElement
    const scrollCoordinates = {
      x: scrollLeft,
      y: scrollTop
    }
    dispatch(saveCoordinates(scrollCoordinates))
  }
  useEffect(() => {
    dispatch(checkLocalStorageEyesState())
  }, [dispatch, scrollElement])

  return (
    <Wrapper>
      {Object.entries(eyesState).map(([name, { pos, viewed }]) =>
        name === 'A' ? (
          <MainEyeWrapper
            to={`/articles/${getURL(name)}`}
            y={pos.y}
            x={pos.x}
            key={name}
            onClick={onEyeClick}
          >
            <img src={eyeMainSvg} alt='eye-main' />
          </MainEyeWrapper>
        ) : (
          <EyeWrapper
            to={`/articles/${getURL(name)}`}
            y={pos.y}
            x={pos.x}
            key={name}
            viewed={viewed ? 1 : 0}
            onClick={onEyeClick}
          >
            {viewed ? (
              <img src={eyeMainSvg} alt='eye-main' />
            ) : (
              <>
                <img src={eyeHoveredSvg} alt='eye-hoveresd' />
                <img src={eyeClosedSvg} alt='eye-closed' />
              </>
            )}
          </EyeWrapper>
        )
      )}
    </Wrapper>
  )
}

export default MapEyesLayer
