import React, { useRef, useEffect } from 'react'
import lottie from 'lottie-web'
import { useSelector } from 'react-redux'

import fingerAnimationJSON from '../../../assets/animations/fingerAnimation.json'

import { ComponentWrapper, Text, AnimationWrapper } from './styled'

const MapFinger = () => {
  const animationContainer = useRef(null)

  const showIcon = useSelector(({ showFIngerIcon }) => showFIngerIcon)

  useEffect(() => {
    const animate = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: fingerAnimationJSON
    })

    return () => animate.destroy()
  }, [])

  return (
    <ComponentWrapper showIcon={showIcon}>
      <AnimationWrapper ref={animationContainer} />
      <Text>Click and hold to navigate</Text>
    </ComponentWrapper>
  )
}

export default MapFinger
