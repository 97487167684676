import { useCallback } from 'react'
import { mapPage } from '../consts/mapPageConsts'

const useParticlesCanvas = (smallCount, bigCount) => {
  const { pageWidth, pageHeight } = mapPage
  const smallParticleSizes = [10, 5, 2]
  const bigParticleSizes = [300, 200]

  // Helper Functions
  const randomInteger = (min, max) =>
    Math.floor(Math.random() * (max - min + 1)) + min
  const randomPosX = useCallback(
    canvasWidth => randomInteger(0, canvasWidth),
    []
  )
  const randomPosY = useCallback(
    canvasHeight => randomInteger(0, canvasHeight),
    []
  )

  const randomElement = array => array[Math.floor(Math.random() * array.length)]
  // />

  const drawParticles = useCallback(
    (canvas, scale) => {
      const ctx = canvas.getContext('2d')

      const canvasWidth = Math.ceil(pageWidth * scale)
      const canvasHeight = Math.ceil(
        scale === 0.33 ? pageHeight * 0.35 : pageHeight * scale
      )

      class Particle {
        constructor (type) {
          this.radius =
            type === 'small'
              ? Math.ceil(randomElement(smallParticleSizes) * scale)
              : Math.ceil(randomElement(bigParticleSizes) * scale)
          this.x = randomPosX(canvasWidth)
          this.y = randomPosY(canvasHeight)
          this.movePos = {
            x: randomPosX(canvasWidth),
            y: randomPosY(canvasHeight)
          }
          this.type = type
        }

        fill = ctx => {
          let fill = null
          if (this.type === 'small') {
            const gradient = ctx.createLinearGradient(
              this.x,
              this.y,
              this.x + this.radius,
              this.y + this.radius
            )
            gradient.addColorStop(0, '#3e4649')
            gradient.addColorStop(0.33, '#334249')
            gradient.addColorStop(0.66, '#2b3a3f')
            gradient.addColorStop(1, '#202f36')
            fill = gradient
          } else {
            fill = `rgba(36, 50, 56, 0.4)`
          }
          return fill
        }

        draw = ctx => {
          ctx.beginPath()
          ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2)
          ctx.fillStyle = this.fill(ctx)
          ctx.fill()
        }

        // move = () => {
        //   if (this.x < this.movePos.x) {
        //     this.x += 0.08
        //   }
        //   if (this.x > this.movePos.x) {
        //     this.x -= 0.08
        //   }
        //   if (this.y < this.movePos.y) {
        //     this.y += 0.08
        //   }
        //   if (this.y > this.movePos.y) {
        //     this.y -= 0.08
        //   }

        //   if (this.x === this.movePos.x) {
        //     this.movePos.x = randomPosX(canvasWidth)
        //   }
        //   if (this.y === this.movePos.y) {
        //     this.movePos.y = randomPosY(canvasHeight)
        //   }

        //   this.draw(ctx)
        // }
      }
      //
      const particlesCountArrEmpty = [...Array(smallCount + bigCount).keys()]
      const particles = []
      particlesCountArrEmpty.forEach((_, i) => {
        const type = i + 1 <= smallCount ? 'small' : 'big'
        particles.push(new Particle(type))
      })
      particles.forEach(particle => particle.draw(ctx))
    },
    [
      bigCount,
      smallCount,
      bigParticleSizes,
      smallParticleSizes,
      randomPosX,
      randomPosY,
      pageWidth,
      pageHeight
    ]
  )

  return { drawParticles }
}

export default useParticlesCanvas
