import React from 'react'

import MainLayout from '../components/LayoutsRelated/Layouts/MainLayout'
import MapPageWrapper from '../components/MapPageRelated/MapPageWrapper/MapPageWrapper'
import MapFinger from '../components/MapPageRelated/MapFinger/MapFinger'
import WithRouteGuard from '../components/Shared/WithRouteGuard/WithRouteGuard'

const MapPageWrapperWithRouteGuard = WithRouteGuard(MapPageWrapper)

const Map = () => {
  return (
    <MainLayout>
      <MapFinger />
      <MapPageWrapperWithRouteGuard />
    </MainLayout>
  )
}

export default Map
