import { useCallback } from 'react'
import { useSelector } from 'react-redux'

const useScroll = () => {
  const { mapRecenter } = useSelector(state => state)

  const getScrollCoordinates = useCallback((width, height, windowHeight) => {
    const isMobile = navigator.userAgent.includes('Mobile')
    const windowWidth = isMobile ? window.screen.width : window.innerWidth
    const scrollFromLeft = (width - windowWidth) / 2
    const scrollFromTop = (height - windowHeight) / 2
    return [scrollFromLeft, scrollFromTop]
  }, [])

  const scrollToCenter = useCallback(
    elementToScroll => {
      const heightString = getComputedStyle(elementToScroll, null).height
      const windowHeight = Number(
        heightString.slice(0, heightString.length - 2)
      )
      if (mapRecenter) {
        const { x, y } = mapRecenter
        elementToScroll.scroll(x, y)
      } else {
        const width = elementToScroll.children[0].offsetWidth
        const height = elementToScroll.children[0].offsetHeight
        const [scrollFromLeft, scrollFromTop] = getScrollCoordinates(
          width,
          height,
          windowHeight
        )
        elementToScroll.scroll(scrollFromLeft, scrollFromTop)
      }
    },
    [getScrollCoordinates, mapRecenter]
  )

  return { scrollToCenter }
}

export default useScroll
