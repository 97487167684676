import styled from 'styled-components'

import ConceptikLogo from '../../Shared/svg/ConceptikLogo'

import { removeImageDrag } from '../../../styled/extends'
import { siteBreakPoints, zIndex, colors } from '../../../consts/styleConsts'
const { textIndex } = zIndex
const { grey } = colors.grey
const { small } = siteBreakPoints
const width1500 = 1500

export const InstructionText = styled.span`
  width: 446px;
  color: ${grey};
  font-family: 'Plex-Light';
  font-size: 24px;
  line-height: 37px;
  font-weight: 300;
  letter-spacing: 0;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 58%;
  transform: translate(-50%, -50%);
  z-index: ${textIndex};
  pointer-events: none;
  @media only screen and (max-width: ${width1500}px) {
    width: 230px;
    font-size: 15px;
    line-height: 25px;
    top: 59%;
  }
  @media only screen and (max-width: ${small}px) {
    top: 60%;
  }
`
export const Title = styled.span`
  font-size: 44px;
  line-height: 57px;
  color: ${grey};
  letter-spacing: 5px;
  position: absolute;
  left: 50%;
  top: 38%;
  transform: translate(-50%, -50%);
  font-family: Plex-Bold;
  text-transform: uppercase;
  z-index: ${textIndex};
  pointer-events: none;
  @media only screen and (max-width: ${width1500}px) {
    font-size: 24px;
    top: 40%;
  }
  @media only screen and (max-width: ${small}px) {
    top: 38%;
  }
`
export const LogoContainer = styled.a`
  display: block;
  position: absolute;
  bottom: 1.1rem;
  left: 50%;
  transform: translateX(-50%);
  max-width: 18rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 4;
  ${removeImageDrag};
  @media only screen and (max-width: ${width1500}px) {
    bottom: 0rem;
  }
`
export const LogoText = styled.span`
  color: ${grey};
  font-family: 'Plex-Light';
  font-size: 1rem;
  line-height: 1.68rem;
  font-weight: 300;
  letter-spacing: 0;
  @media only screen and (max-width: ${width1500}px) {
    font-size: 15px;
    line-height: 25px;
  }
`
export const Logo = styled(ConceptikLogo)`
  width: 6.36rem;
  position: relative;
  top: 0;
  @media only screen and (max-width: ${width1500}px) {
    top: -0.4rem;
  }
  @media only screen and (max-width: ${small}px) {
    height: 45px;
    top: -0.3rem;
  }
`
