import { useState, useCallback } from 'react'

const useResize = () => {
  const initalScale = () => {
    const windowWidth = window.innerWidth
    if (windowWidth > 1500) {
      return 1
    }
    if (windowWidth > 576 && windowWidth <= 1500) {
      return 0.5
    }
    if (windowWidth <= 576) {
      return 0.33
    }
  }

  const [scale, setScale] = useState(initalScale)

  const updateScale = useCallback(() => {
    const windowWidth = window.innerWidth
    if (windowWidth > 1500 && scale !== 1) {
      setScale(1)
    }
    if (windowWidth > 576 && windowWidth <= 1500 && scale !== 0.5) {
      setScale(0.5)
    }
    if (windowWidth <= 576 && scale !== 0.33) {
      setScale(0.33)
    }
  }, [scale])

  const addResizeListener = useCallback(() => {
    window.addEventListener('resize', updateScale)
  }, [updateScale])
  const removeResizeListener = useCallback(() => {
    window.removeEventListener('resize', updateScale)
  }, [updateScale])

  return { scale, addResizeListener, removeResizeListener }
}
export default useResize
