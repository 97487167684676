import styled, { css } from 'styled-components'
import { zIndex, colors, siteBreakPoints } from '../../../consts/styleConsts'

const { mapFinger } = zIndex
const { grey } = colors.grey
const { small, xxLarge } = siteBreakPoints

export const ComponentWrapper = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${mapFinger};
  bottom: 0;
  margin-bottom: 11px;
  text-align: center;
  pointer-events: none;
  width: 100%;
  opacity: ${({ showIcon }) => (showIcon ? 1 : 0)};
  transition: 0.3s ease;
`
export const AnimationWrapper = styled.div`
  width: 140px;
  height: 140px;
  margin: 0px auto;
  margin-bottom: -30px;
  @media only screen and (max-width: ${xxLarge}px) {
    width: 100px;
    height: 100px;
    margin-bottom: -25px;
  }
  @media only screen and (max-width: ${small}px) {
    ${({ isMobile }) =>
      isMobile &&
      css`
        width: 80px;
        height: 80px;
        margin-bottom: -20px;
      `}
  }
`
export const Text = styled.span`
  font-family: Plex-Light;
  color: ${grey};
  font-size: 0.72rem;
`
