import styled from 'styled-components'
import { zIndex, siteBreakPoints } from '../../../consts/styleConsts'
import { mapPage } from '../../../consts/mapPageConsts'

const { pageWidth, pageHeight } = mapPage
const { small } = siteBreakPoints
const width1500 = 1500
const { canvasLinesIndex } = zIndex

export const Canvas = styled.canvas`
  width: ${pageWidth}px;
  height: ${pageHeight}px;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: ${canvasLinesIndex};
  @media only screen and (max-width: ${width1500}px) {
    width: ${pageWidth * 0.5}px;
    height: ${pageHeight * 0.5}px;
  }
  @media only screen and (max-width: ${small}px) {
    width: ${pageWidth * 0.33}px;
    height: ${pageHeight * 0.35}px;
  }
`
