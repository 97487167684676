import { canvasLines } from '../consts/canvasConsts'
import { useCallback } from 'react'

import { mapPage } from '../consts/mapPageConsts'

const useCanvas = eyesState => {
  const { pageWidth, pageHeight } = mapPage
  const { lineColor, lineWidth } = canvasLines

  const percentToPixel = useCallback(
    (percent, axis, canvasWidth, canvasHeight) =>
      Math.ceil(
        axis === 'x'
          ? (percent / 100) * canvasWidth
          : (percent / 100) * canvasHeight
      ),
    []
  )

  const drawLines = useCallback(
    (canvas, scale) => {
      const context = canvas.getContext('2d')
      const canvasWidth = Math.ceil(pageWidth * scale)
      const canvasHeight = Math.ceil(
        scale === 0.33 ? pageHeight * 0.35 : pageHeight * scale
      )

      context.lineWidth = lineWidth
      context.strokeStyle = lineColor

      Object.values(eyesState).forEach(({ pos, relations }) => {
        const startX = percentToPixel(pos.x, 'x', canvasWidth, canvasHeight)
        const startY = percentToPixel(pos.y, 'y', canvasWidth, canvasHeight)
        relations.forEach(pointName => {
          const endX = percentToPixel(
            eyesState[pointName].pos.x,
            'x',
            canvasWidth,
            canvasHeight
          )
          const endY = percentToPixel(
            eyesState[pointName].pos.y,
            'y',
            canvasWidth,
            canvasHeight
          )
          context.beginPath()
          context.moveTo(startX, startY)
          context.lineTo(endX, endY)
          context.stroke()
        })
      })
    },
    [percentToPixel, eyesState, pageWidth, pageHeight, lineWidth, lineColor]
  )

  return { drawLines }
}

export default useCanvas
