import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { hideFingerIcon } from '../redux/showFingerState/actions'

const useMapPageNavigation = () => {
  const [elementToScroll, setElementToScroll] = useState(null)

  const dispatch = useDispatch()

  let isMousePressed = false
  const setIsMousePressed = param => (isMousePressed = param)
  let dragStartX = 0
  const setDratStartX = param => (dragStartX = param)
  let dragStartY = 0
  const setDratStartY = param => (dragStartY = param)
  let elementX = 0
  const setElementX = param => (elementX = param)
  let elementY = 0
  const setElementY = param => (elementY = param)

  const setScrollElement = useCallback(el => {
    setElementToScroll(el)
  }, [])

  const onMouseDown = e => {
    const screenX = e.screenX
    const screenY = e.screenY
    setIsMousePressed(true)
    setDratStartX(screenX)
    setDratStartY(screenY)
    setElementX(elementToScroll.scrollLeft)
    setElementY(elementToScroll.scrollTop)
    // }
  }

  const onMouseUp = e => {
    const screenX = e.screenX
    const screenY = e.screenY
    setIsMousePressed(false)
    setDratStartX(screenX)
    setDratStartY(screenY)
    setElementX(elementToScroll.scrollLeft)
    setElementY(elementToScroll.scrollTop)
  }

  const onMouseMove = e => {
    const screenX = e.screenX
    const screenY = e.screenY
    if (isMousePressed) {
      const moveX = screenX - dragStartX
      const moveY = screenY - dragStartY
      elementToScroll.scroll(elementX - moveX, elementY - moveY)
    }
  }

  const removeWheelScroll = useCallback(() => {
    if (elementToScroll) {
      elementToScroll.onwheel = e => e.preventDefault()
    }
  }, [elementToScroll])

  const addWheelScroll = useCallback(() => {
    if (elementToScroll) {
      elementToScroll.onwheel = () => true
    }
  }, [elementToScroll])

  //
  const onScrollFunc = useCallback(() => {
    const maxScrollX = elementToScroll.scrollWidth
    const maxScrollY = elementToScroll.scrollHeight
    const currentX = elementToScroll.scrollLeft
    const currentY = elementToScroll.scrollTop
    if (currentX === 0) {
      elementToScroll.scroll(1, currentY)
    }
    if (currentX + window.innerWidth === maxScrollX) {
      elementToScroll.scroll(currentX - 1, currentY)
    }
    if (currentY === 0) {
      elementToScroll.scroll(currentX, 1)
    }
    if (currentY + window.innerHeight === maxScrollY) {
      elementToScroll.scroll(currentX, currentY - 1)
    }
    // Hide finger
    if (
      elementToScroll.scrollTop + window.innerHeight >
      elementToScroll.scrollHeight - 80
    ) {
      dispatch(hideFingerIcon)
    }
  }, [elementToScroll, dispatch])

  const addScrollListener = useCallback(() => {
    if (elementToScroll) {
      elementToScroll.addEventListener('scroll', onScrollFunc)
    }
  }, [elementToScroll, onScrollFunc])

  return {
    onMouseDown,
    onMouseUp,
    onMouseMove,
    setScrollElement,
    removeWheelScroll,
    addWheelScroll,
    addScrollListener
  }
}

export default useMapPageNavigation
